<template>

        <div>
            <form @submit.prevent="$emit('submitted')">
                <div class=""
                    :class="hasActions ? 'sm:rounded-tl-md sm:rounded-tr-md' : 'sm:rounded-md'">
                    <slot name="form"></slot>
                </div>

                <div class="flex items-center justify-end  text-right mt-2 " v-if="hasActions">
                    <slot name="actions"></slot>
                </div>
            </form>
        </div>

</template>

<script>
    import { defineComponent } from 'vue'
    import JetSectionTitle from './SectionTitle.vue'

    export default defineComponent({
        emits: ['submitted'],

        components: {
            JetSectionTitle,
        },

        computed: {
            hasActions() {
                return !! this.$slots.actions
            }
        }
    })
</script>
